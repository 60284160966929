import React, { useState } from "react";

import AddBookForm from "../components/AddBookForm";
import BookList from "../components/BookList";

const Home = () => {
  const [featureFilter, setFeatureFilter] = useState(false);

  const handleFeatureFilter = () => {
    setFeatureFilter(true);
  };

  const handleAllFilter = () => {
    setFeatureFilter(false);
  };

  return (
    <main className="py-12 2xl:px-6">
      <div className="container grid xl:grid-cols-[auto_350px] 2xl:grid-cols-[auto_400px] gap-4 2xl:gap-8">
        <div className="order-2 xl:-order-1">
          <div className="flex items-center justify-between mb-12">
            <h4 className="mt-2 text-xl font-bold">Book List</h4>

            <div className="flex items-center space-x-4">
              <button
                className={`filter-btn ${!featureFilter && "active"}-filter`}
                id="lws-filterAll"
                onClick={handleAllFilter}
              >
                All
              </button>
              <button
                className={`filter-btn ${featureFilter && "active"}-filter`}
                id="lws-filterFeatured"
                onClick={handleFeatureFilter}
              >
                Featured
              </button>
            </div>
          </div>
          <BookList featureFilter={featureFilter} />
        </div>
        <AddBookForm />
      </div>
    </main>
  );
};

export default Home;
