import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Book from "./Book";
import loadBooks from "../redux/books/thunk/loadBooks";

const BookList = ({ featureFilter }) => {
  const dispatch = useDispatch();
  const { books, filters } = useSelector((state) => state);

  const [allBooks, setAllBooks] = useState(books);

  useEffect(() => {
    if (featureFilter) {
      const filteredBooks = books.filter((book) => book.featured === true);
      setAllBooks(filteredBooks);
    } else {
      setAllBooks(books);
    }
  }, [books, featureFilter]);

  useEffect(() => {
    if (filters) {
      const filteredBooks = books.filter((book) =>
        book.name.toLowerCase().includes(filters.toLowerCase())
      );
      setAllBooks(filteredBooks);
    } else {
      setAllBooks(books);
    }
  }, [books, filters]);

  useEffect(() => {
    dispatch(loadBooks());
  }, [dispatch]);

  return (
    <div div className="lws-bookContainer">
      {allBooks.map((book) => (
        <Book key={book.id} book={book} />
      ))}
    </div>
  );
};

export default BookList;
