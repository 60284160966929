import { SEARCH_BOOKS } from "./actionType";

const filterReducer = (state = "", action) => {
    switch (action.type) {
        case SEARCH_BOOKS:
            return action.payload;
        default:
            return state;
    }
};

export default filterReducer;