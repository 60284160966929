import { loadBooks as loadBooksAction } from "../actions";

const loadBooks = () => {
    return async (dispatch) => {
        const response = await fetch("http://localhost:9000/books");
        const books = await response.json();
        dispatch(loadBooksAction(books));
    };
};

export default loadBooks;