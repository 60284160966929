import { LOAD_BOOKS, ADD_BOOK, EDIT_BOOK, REMOVE_BOOK } from "./actionType";

const bookReducer = (state = [], action) => {
    switch (action.type) {
        case LOAD_BOOKS:
            return action.payload;
        case ADD_BOOK:
            return [...state, action.payload];
        case EDIT_BOOK:
            return state.map((book) => {
                if (book.id === action.payload.id) {
                    return {
                        ...book,
                        ...action.payload,
                    };
                }
                return book;
            });
        case REMOVE_BOOK:
            return state.filter((book) => book.id !== action.payload.id);
        default:
            return state;
    }
};

export default bookReducer;