import { addBook } from "../actions";

const addBookThunk = (book) => {
    return async (dispatch) => {
        const response = await fetch("http://localhost:9000/books", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Charset": "utf-8",
            },
            body: JSON.stringify(book),
        });
        const newBook = await response.json();
        dispatch(addBook(newBook));
    };
};

export default addBookThunk;