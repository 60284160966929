import { TO_BE_EDITED_BOOK } from "./actionType";

const toBeEditedReducer = (state = {}, action) => {
    switch (action.type) {
        case TO_BE_EDITED_BOOK:
            return action.payload;
        default:
            return state;
    }
};

export default toBeEditedReducer;