import Header from "./components/Header";
import './App.css';
import Home from "./pages/Home";

function App() {
  return (<>
    <Header />
    <Home />
  </>
  );
}

export default App;
