import { combineReducers } from 'redux';

import bookReducer from "./books/bookReducer";
import filterReducer from "./filters/filterReducer";
import toBeEditedReducer from "./toBeEdited/toBeEditedReducer";

const rootReducer = combineReducers({
    books: bookReducer,
    filters: filterReducer,
    toBeEdited: toBeEditedReducer,
});

export default rootReducer;