import { editBook } from "../actions";

const editBookThunk = (book) => {
    return async (dispatch) => {
        const response = await fetch(`http://localhost:9000/books/${book.id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Charset": "utf-8",
            },
            body: JSON.stringify(book),
        });
        const updatedBook = await response.json();
        dispatch(editBook(updatedBook));
    };
};

export default editBookThunk;