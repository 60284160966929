import { removeBook } from "../actions";

const removeBookThunk = (book) => {
    return async (dispatch) => {
        await fetch(`http://localhost:9000/books/${book.id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Charset": "utf-8",
            },
            body: JSON.stringify(book),
        });

        dispatch(removeBook(book));
    };
};

export default removeBookThunk;