import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import addBookThunk from "../redux/books/thunk/addBook";
import editBookThunk from "../redux/books/thunk/editbook";
import { toBeEditedBook } from "../redux/toBeEdited/actions";

const AddBookForm = () => {
  const dispatch = useDispatch();
  const { toBeEdited } = useSelector((state) => state);

  const [book, setBook] = useState({
    name: "",
    author: "",
    thumbnail: "",
    price: 1,
    rating: 1,
    featured: false,
  });
  const [isEditing, setIsEditing] = useState(false);

  const resetSetBook = () => {
    setBook({
      name: "",
      author: "",
      thumbnail: "",
      price: 1,
      rating: 1,
      featured: false,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBook({ ...book, [name]: value });
    if (name === "featured") {
      setBook({ ...book, [name]: e.target.checked });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isEditing) {
      dispatch(editBookThunk(book));
      resetSetBook();
      dispatch(toBeEditedBook({}));
    } else {
      dispatch(addBookThunk(book));
      resetSetBook();
    }
    setIsEditing(false);
  };

  useEffect(() => {
    if (toBeEdited.name) {
      setBook(toBeEdited);
      setIsEditing(true);
    } else {
      setBook({
        name: "",
        author: "",
        thumbnail: "",
        price: 1,
        rating: 1,
        featured: false,
      });
      setIsEditing(false);
    }
  }, [toBeEdited]);

  return (
    <div>
      <div className="p-4 overflow-hidden bg-white shadow-cardShadow rounded-md">
        <h4 className="mb-8 text-xl font-bold text-center">Add New Book</h4>
        <form className="book-form" onSubmit={handleFormSubmit}>
          <div className="space-y-2">
            <label htmlFor="name">Book Name</label>
            <input
              required
              className="text-input"
              type="text"
              id="input-Bookname"
              name="name"
              value={book.name}
              onChange={handleInputChange}
            />
          </div>

          <div className="space-y-2">
            <label htmlFor="category">Author</label>
            <input
              required
              className="text-input"
              type="text"
              id="input-Bookauthor"
              name="author"
              value={book.author}
              onChange={handleInputChange}
            />
          </div>

          <div className="space-y-2">
            <label htmlFor="image">Image Url</label>
            <input
              required
              className="text-input"
              type="url"
              id="input-Bookthumbnail"
              name="thumbnail"
              value={book.thumbnail}
              onChange={handleInputChange}
            />
          </div>

          <div className="grid grid-cols-2 gap-8 pb-4">
            <div className="space-y-2">
              <label htmlFor="price">Price</label>
              <input
                required
                className="text-input"
                type="number"
                id="input-Bookprice"
                name="price"
                value={book.price}
                onChange={handleInputChange}
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="quantity">Rating</label>
              <input
                required
                className="text-input"
                type="number"
                id="input-Bookrating"
                name="rating"
                min="1"
                max="5"
                value={book.rating}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="flex items-center">
            <input
              id="input-Bookfeatured"
              type="checkbox"
              name="featured"
              className="w-4 h-4"
              checked={book.featured}
              onChange={handleInputChange}
            />
            <label htmlFor="featured" className="ml-2 text-sm">
              {" "}
              This is a featured book{" "}
            </label>
          </div>

          <button type="submit" className="submit" id="submit">
            {isEditing ? "Update" : "Add New"} Book
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddBookForm;
