import { LOAD_BOOKS, ADD_BOOK, EDIT_BOOK, REMOVE_BOOK } from "./actionType";

export const loadBooks = (books) => {
    return {
        type: LOAD_BOOKS,
        payload: books,
    };
};

export const addBook = (book) => {
    return {
        type: ADD_BOOK,
        payload: book,
    };
};

export const editBook = (book) => {
    return {
        type: EDIT_BOOK,
        payload: book,
    };
};

export const removeBook = (book) => {
    return {
        type: REMOVE_BOOK,
        payload: book,
    };
};